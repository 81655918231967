import { SERVICE_PAGE_NAME } from '../constants';
import Experiments from '@wix/wix-experiments';

const getServiceSlugOnUnpublishedSiteExperiment = new Experiments();
getServiceSlugOnUnpublishedSiteExperiment
  .conduct('specs.bookings.GetServiceSlugOnUnpublishedSite', 'false')
  .then();

export const getServiceSlug = async (wixCodeApi, isPreview) => {
  await getServiceSlugOnUnpublishedSiteExperiment.ready();
  const isServiceSlugOnUnpublishedSiteEnabled = getServiceSlugOnUnpublishedSiteExperiment.enabled(
    'specs.bookings.GetServiceSlugOnUnpublishedSite',
  );
  const servicePageName =
    (isServiceSlugOnUnpublishedSiteEnabled
      ? wixCodeApi.site.currentPage.url?.substring(1).toLowerCase()
      : wixCodeApi.site.currentPage.url?.substring(1)) || SERVICE_PAGE_NAME;
  let locationPathArray = wixCodeApi.location.path;
  if (isServiceSlugOnUnpublishedSiteEnabled) {
    locationPathArray = wixCodeApi.location.path.map((subPath) =>
      subPath.toLowerCase(),
    );
  }
  let servicePageIndex = locationPathArray.indexOf(servicePageName);
  const servicePageIsInPath = servicePageIndex !== -1;
  const slugGiven = servicePageIndex !== wixCodeApi.location.path.length - 1;
  if (!servicePageIsInPath && isPreview) {
    servicePageIndex = 1;
  }
  const isServiceSlugExistsInPath = servicePageIsInPath && slugGiven;
  let serviceSlug = isServiceSlugExistsInPath
    ? wixCodeApi.location.path[servicePageIndex + 1]
    : '';
  const shouldFixProblematicSiteStructureInPreview =
    isPreview &&
    !servicePageIsInPath &&
    wixCodeApi.location.path.length === 1 &&
    locationPathArray[0] !== SERVICE_PAGE_NAME;

  if (shouldFixProblematicSiteStructureInPreview) {
    serviceSlug = wixCodeApi.location.path[0];
  }

  if (serviceSlug) {
    serviceSlug = decodeURI(serviceSlug);
  }
  return serviceSlug;
};
